import { Button, Icon, SwanCoreStyleProps, VisuallyHidden } from '@vp/swan'
import { useCopyToClipboard } from '../../../hooks/use-copy-to-clipboard.hook'

export const BtnCopy = ({
  content,
  showInitText = true,
  ...props
}: {
  content: string
  showInitText?: boolean
} & SwanCoreStyleProps) => {
  const { copyStatus, copyToClipboard } = useCopyToClipboard()
  const copyContent = () => copyToClipboard(content)
  return (
    <>
      <Button
        {...props}
        onClick={copyContent}
        iconPosition="left"
        skin="tertiary"
      >
        {!(!showInitText && copyStatus !== 'INITIAL') && (
          <Icon iconType="saveAsCopy" />
        )}
        {showInitText && copyStatus === 'INITIAL' && 'Copy'}
        {copyStatus === 'DONE' && 'Copied'}
        {copyStatus === 'ERROR' && 'Error, try again'}
      </Button>
      <VisuallyHidden aria-live="polite">
        {copyStatus === 'DONE' && 'Copied'}
        {copyStatus === 'ERROR' && 'Error, try again'}
      </VisuallyHidden>
    </>
  )
}
