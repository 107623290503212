import {
  ErrorBoundary,
  ModalDialog,
  ModalDialogBody,
  ModalDialogCloseButton,
  ModalDialogContent,
  ModalDialogHeader,
  ModalDialogNav,
  ModalDialogTitle,
} from '@vp/swan'
import classNames from 'classnames'
import Image from 'gatsby-plugin-sanity-image'
import { CSSProperties, useState } from 'react'
import { BsArrowsAngleExpand } from 'react-icons/bs'
import { Maybe, SanityImageBlock } from '../../../types/gatsby-graphql'
import { SchemaMarkdown } from '../schema-markdown/schema-markdown'
import {
  enlargeButton,
  figcaption,
  figure,
  image,
  imageSkinElevated,
} from './schema-image-block.module.scss'

export const SchemaImageBlock = ({
  node: config,
  className,
  showCaption = true,
  style = {},
}: {
  node?: Maybe<SanityImageBlock>
  className?: string
  style?: CSSProperties
  showCaption?: boolean
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const asset = config?._rawImage?.asset || config?.image?.asset
  if (!asset) return null
  const styleOverride: CSSProperties = {}
  if (config.height !== undefined) {
    styleOverride.height = config.height
  }
  if (config.objectFit !== undefined) {
    styleOverride.objectFit = config.objectFit as CSSProperties['objectFit']
  }

  const imageClasses = ['swan-fluid-image']
  if (config.skin === 'elevated') {
    imageClasses.push(imageSkinElevated)
  }

  const imageElem = (
    <Image
      {...config?._rawImage}
      asset={asset}
      alt={config?.alt || ''}
      className={classNames(image, imageClasses)}
      style={styleOverride}
    />
  )
  return (
    <ErrorBoundary>
      <figure
        className={classNames(figure, className)}
        style={{ width: config.width, ...style }}
      >
        {imageElem}
        {config.enableZoom ? (
          <button className={enlargeButton} onClick={() => setIsOpen(true)}>
            <BsArrowsAngleExpand />
          </button>
        ) : null}
        {!!config.caption && showCaption && (
          <figcaption className={figcaption}>
            <SchemaMarkdown node={config.caption} />
          </figcaption>
        )}
      </figure>
      {!config.enableZoom ? null : (
        <ModalDialog
          aria-labelledby="image block"
          isOpen={isOpen}
          onRequestDismiss={() => {
            setIsOpen(false)
          }}
          takeOver
        >
          <ModalDialogContent>
            <ModalDialogNav>
              <ModalDialogCloseButton accessibleText="Close" />
            </ModalDialogNav>
            <ModalDialogHeader>
              <ModalDialogTitle>{config.caption}</ModalDialogTitle>
            </ModalDialogHeader>
            <ModalDialogBody>{imageElem}</ModalDialogBody>
          </ModalDialogContent>
        </ModalDialog>
      )}
    </ErrorBoundary>
  )
}
