import { graphql, useStaticQuery } from 'gatsby'
import { PageTitle, SlugPrefix } from '../constants/slug-prefix'
import {
  SanityComponentPageConnection,
  SanityContentPageConnection,
  SanityDocumentationPageConnection,
  SanityFoundationPageConnection,
  SanityPatternPageConnection,
} from '../gatsby/page-types'

type AllPages = Array<{
  title: string
  nodes: Array<{
    id: string
    title?: string | null
    slug: { current?: string | null } | null
  }>
}>

export function useAllPages(): AllPages {
  const allPages = useStaticQuery<{
    allSanityComponentPage: Partial<SanityComponentPageConnection>
    allSanityContentPage: Partial<SanityContentPageConnection>
    allSanityDocumentationPage: Partial<SanityDocumentationPageConnection>
    allSanityFoundationPage: Partial<SanityFoundationPageConnection>
    allSanityPatternPage: Partial<SanityPatternPageConnection>
  }>(graphql`
    query allPages {
      allSanityComponentPage(sort: { title: ASC }) {
        nodes {
          id
          title
          slug {
            current
          }
        }
      }
      allSanityContentPage(sort: [{ orderRank: ASC }, { title: ASC }]) {
        nodes {
          id
          title
          slug {
            current
          }
        }
      }
      allSanityDocumentationPage(sort: [{ orderRank: ASC }, { title: ASC }]) {
        nodes {
          id
          title
          slug {
            current
          }
        }
      }
      allSanityFoundationPage(sort: [{ orderRank: ASC }, { title: ASC }]) {
        nodes {
          id
          title
          slug {
            current
          }
        }
      }
      allSanityPatternPage(sort: [{ orderRank: ASC }, { title: ASC }]) {
        nodes {
          id
          title
          slug {
            current
          }
        }
      }
    }
  `)
  return [
    {
      title: PageTitle.documentationPage,
      nodes: [
        {
          id: SlugPrefix.documentationPage,
          slug: { current: SlugPrefix.documentationPage },
          title: 'Overview',
        },
        ...allPages.allSanityDocumentationPage.nodes.map(u => ({
          ...u,
          slug: {
            ...u.slug,
            current: `${SlugPrefix.documentationPage}/${u.slug.current}`,
          },
        })),
      ],
    },
    {
      title: PageTitle.foundationPage,
      nodes: [
        {
          id: SlugPrefix.foundationPage,
          slug: { current: SlugPrefix.foundationPage },
          title: 'Overview',
        },
        ...allPages.allSanityFoundationPage.nodes.map(u => ({
          ...u,
          slug: {
            ...u.slug,
            current: `${SlugPrefix.foundationPage}/${u.slug.current}`,
          },
        })),
      ],
    },
    {
      title: PageTitle.componentPage,
      nodes: [
        {
          id: SlugPrefix.componentPage,
          slug: { current: SlugPrefix.componentPage },
          title: 'Overview',
        },
        ...allPages.allSanityComponentPage.nodes.map(u => ({
          ...u,
          slug: {
            ...u.slug,
            current: `${SlugPrefix.componentPage}/${u.slug.current}`,
          },
        })),
      ],
    },
    {
      title: PageTitle.contentPage,
      nodes: [
        {
          id: SlugPrefix.contentPage,
          slug: { current: SlugPrefix.contentPage },
          title: 'Overview',
        },
        ...allPages.allSanityContentPage.nodes.map(u => ({
          ...u,
          slug: {
            ...u.slug,
            current: `${SlugPrefix.contentPage}/${u.slug.current}`,
          },
        })),
      ],
    },
    {
      title: PageTitle.patternPage,
      nodes: [
        {
          id: SlugPrefix.patternPage,
          slug: { current: SlugPrefix.patternPage },
          title: 'Overview',
        },
        ...allPages.allSanityPatternPage.nodes.map(u => ({
          ...u,
          slug: {
            ...u.slug,
            current: `${SlugPrefix.patternPage}/${u.slug.current}`,
          },
        })),
      ],
    },
  ]
}
