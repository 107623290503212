exports.components = {
  "component---src-modules-component-pages-component-pages-template-tsx": () => import("./../../../src/modules/component-pages/component-pages.template.tsx" /* webpackChunkName: "component---src-modules-component-pages-component-pages-template-tsx" */),
  "component---src-modules-content-pages-content-pages-template-tsx": () => import("./../../../src/modules/content-pages/content-pages.template.tsx" /* webpackChunkName: "component---src-modules-content-pages-content-pages-template-tsx" */),
  "component---src-modules-docs-pages-docs-pages-template-tsx": () => import("./../../../src/modules/docs-pages/docs-pages.template.tsx" /* webpackChunkName: "component---src-modules-docs-pages-docs-pages-template-tsx" */),
  "component---src-modules-foundation-pages-foundation-all-tokens-foundation-all-tokens-template-tsx": () => import("./../../../src/modules/foundation-pages/foundation-all-tokens/foundation-all-tokens.template.tsx" /* webpackChunkName: "component---src-modules-foundation-pages-foundation-all-tokens-foundation-all-tokens-template-tsx" */),
  "component---src-modules-foundation-pages-foundation-icons-template-tsx": () => import("./../../../src/modules/foundation-pages/foundation-icons.template.tsx" /* webpackChunkName: "component---src-modules-foundation-pages-foundation-icons-template-tsx" */),
  "component---src-modules-foundation-pages-foundation-pages-template-tsx": () => import("./../../../src/modules/foundation-pages/foundation-pages.template.tsx" /* webpackChunkName: "component---src-modules-foundation-pages-foundation-pages-template-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-all-pages-index-tsx": () => import("./../../../src/pages/all-pages/index.tsx" /* webpackChunkName: "component---src-pages-all-pages-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-swan-availability-tsx": () => import("./../../../src/pages/swan/availability.tsx" /* webpackChunkName: "component---src-pages-swan-availability-tsx" */),
  "component---src-pages-swan-changelog-tsx": () => import("./../../../src/pages/swan/changelog.tsx" /* webpackChunkName: "component---src-pages-swan-changelog-tsx" */),
  "component---src-pages-swan-components-tsx": () => import("./../../../src/pages/swan/components.tsx" /* webpackChunkName: "component---src-pages-swan-components-tsx" */),
  "component---src-pages-swan-content-tsx": () => import("./../../../src/pages/swan/content.tsx" /* webpackChunkName: "component---src-pages-swan-content-tsx" */),
  "component---src-pages-swan-foundations-tsx": () => import("./../../../src/pages/swan/foundations.tsx" /* webpackChunkName: "component---src-pages-swan-foundations-tsx" */),
  "component---src-pages-swan-get-started-tsx": () => import("./../../../src/pages/swan/get-started.tsx" /* webpackChunkName: "component---src-pages-swan-get-started-tsx" */),
  "component---src-pages-swan-index-tsx": () => import("./../../../src/pages/swan/index.tsx" /* webpackChunkName: "component---src-pages-swan-index-tsx" */),
  "component---src-pages-swan-patterns-tsx": () => import("./../../../src/pages/swan/patterns.tsx" /* webpackChunkName: "component---src-pages-swan-patterns-tsx" */),
  "component---src-pages-swan-preview-tsx": () => import("./../../../src/pages/swan/preview.tsx" /* webpackChunkName: "component---src-pages-swan-preview-tsx" */),
  "component---src-pages-swan-versions-tsx": () => import("./../../../src/pages/swan/versions.tsx" /* webpackChunkName: "component---src-pages-swan-versions-tsx" */)
}

