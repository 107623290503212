import { Button, Icon, SwanCoreStyleProps, VisuallyHidden } from '@vp/swan'
import { useCopyToClipboard } from '../../../../hooks/use-copy-to-clipboard.hook'
import { compressContent } from '../../../../utils/compress-content'

export const BtnShare = ({
  content,
  language,
  renderMultipleComponents,
  ...props
}: {
  content: string
  language: string
  renderMultipleComponents?: boolean
} & SwanCoreStyleProps) => {
  const { copyStatus, copyToClipboard } = useCopyToClipboard({
    statusResetTime: 1500,
  })

  const copySharedUrl = () => {
    const url = `/swan/preview/?language=${encodeURIComponent(
      language,
    )}&code=${encodeURIComponent(
      compressContent(content),
    )}&renderMultipleComponents=${renderMultipleComponents}`

    copyToClipboard(`${window.location.origin}${url}`)
  }

  return (
    <>
      <Button
        {...props}
        iconPosition="left"
        skin="tertiary"
        onClick={copySharedUrl}
      >
        <Icon iconType="share" />
        {copyStatus === 'INITIAL' && 'Share'}
        {copyStatus === 'DONE' && 'Copied'}
        {copyStatus === 'ERROR' && 'Error, try again'}
      </Button>
      <VisuallyHidden aria-live="polite">
        {copyStatus === 'DONE' && 'Copied'}
        {copyStatus === 'ERROR' && 'Error, try again'}
      </VisuallyHidden>
    </>
  )
}
