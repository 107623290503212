export const SlugPrefix = {
  page: '',
  root: '/',
  availabilityPage: '/swan/availability',
  swan: '/swan',
  componentPage: '/swan/components',
  foundationPage: '/swan/foundations',
  contentPage: '/swan/content',
  documentationPage: '/swan/get-started',
  versionsPage: '/swan/versions',
  patternPage: '/swan/patterns',
  changelogPage: '/swan/changelog',
}

export const PageTitle: Record<keyof typeof SlugPrefix, string> = {
  page: '',
  root: 'vista.design',
  availabilityPage: 'SWAN Proxy Availability',
  swan: 'SWAN',
  componentPage: 'Components',
  foundationPage: 'Foundations',
  contentPage: 'Content',
  documentationPage: 'Get started',
  versionsPage: 'SWAN versions',
  patternPage: 'Patterns',
  changelogPage: 'Changelog',
}

export enum LinkType {
  External = 'External',
  Relative = 'Relative',
  DownloadExternal = 'DownloadExternal',
  DownloadFile = 'DownloadFile',
}
