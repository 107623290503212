import * as swanScope from '@vp/swan'
import classNames from 'classnames'
import { ReactNode, useEffect, useRef, useState } from 'react'
import { LiveError, LiveProvider as ReactCodeProvider } from 'react-live'
import {
  Maybe,
  SanityCode,
  SanityCodePreview,
  Scalars,
} from '../../../../types/gatsby-graphql'
import { useUserPreferencesContext } from '../../layout'
import { CodeLiveEditorHtml, CodeLivePreviewHtml } from './code-block-html'
import { CodeLiveEditorReact, CodeLivePreviewReact } from './code-block-react'
import { CodeEditor } from './code-editor'
import { HtmlCodeProvider } from './code-html.context'
import { EDITOR_MODE } from './code.constants'
import {
  CodeContextProps,
  CodeContextProvider,
  useCodeContext,
} from './code.context'
import {
  codeEditorContainer,
  codeEditorToggleContainer,
  codeEditorWrapper,
  codeError,
  playgroundContainer,
  previewContainer,
  previewContainerBackground,
  previewContainerBackgroundDark,
} from './code.module.scss'
import { usePreventSlashBubbling } from './usePreventSlashBubbling'

const {
  Box,
  Button,
  ErrorBoundary,
  Icon,
  TabContent,
  TabHeader,
  Tabs,
  TabsContents,
  TabsHeaders,
  Typography,
  P,
} = swanScope

function getActiveTabId(node?: Maybe<SanityCodePreview>) {
  if (!node) return undefined
  if (node.reactCode?.code) return 'reactCode'
  if (node.htmlCode?.code) return 'htmlCode'
  return node.codes?.[0]?._key
}

const ContextWrapper = ({
  codeContextValues,
  reactCode,
  htmlCode,
  renderMultipleComponents,
  children,
}: {
  codeContextValues: CodeContextProps
  reactCode?: Maybe<SanityCode>
  htmlCode?: Maybe<SanityCode>
  renderMultipleComponents?: Maybe<Scalars['Boolean']>
  children: ReactNode
}) => {
  // remove the default export from swan before passing into react-live
  const { default: _, ...scope } = swanScope
  return (
    <CodeContextProvider {...codeContextValues}>
      <ErrorBoundary>
        <ReactCodeProvider
          code={reactCode?.code}
          scope={scope}
          noInline={renderMultipleComponents}
        >
          <HtmlCodeProvider htmlCode={htmlCode}>
            <div className={classNames([playgroundContainer])}>{children}</div>
          </HtmlCodeProvider>
        </ReactCodeProvider>
      </ErrorBoundary>
    </CodeContextProvider>
  )
}

const PreviewWrapper = ({ children }: { children: ReactNode }) => {
  const ref = useRef()
  const { compactMode, darkMode, bgc, codeKey } = useCodeContext()
  usePreventSlashBubbling(ref, 'CodeLivePreviewReact')
  return (
    <Box
      ref={ref}
      id={`live-preview-${codeKey}`}
      className={classNames({
        [previewContainerBackground]: true,
        [previewContainerBackgroundDark]: darkMode,
      })}
    >
      <Box
        bgc={bgc}
        darkMode={darkMode}
        compactMode={compactMode}
        className={classNames({
          [previewContainer]: true,
        })}
      >
        {/* Each time this component is re-rendered (for example, when a user switches tabs causing codeKey to be updated)
      the message in this live region will be announced by assistive technology (AT) to notify the user of the preview update. 
      The "polite" setting forces AT to wait until the user pauses before announcing the update. */}
        <P aria-live="polite" visuallyHidden>
          The preview has been updated.
        </P>
        {children}
      </Box>
    </Box>
  )
}

const EditorWrapper = ({
  isCodePreviewable = true,
  /* Optional padding prevents the "Show/Hide code" button from overlapping the element below it
                                                                                              in scenarios where that element's design does not accommodate the overlap.
                                                                                              For example, the scenario where the element below is NOT tabs */
  showPadding = false,
  children,
}: {
  isCodePreviewable?: boolean
  showPadding?: boolean
  children: ReactNode
}) => {
  const { showCodeByDefault } = useUserPreferencesContext()
  const { codeKey, alwaysShowEditor } = useCodeContext()
  const [showEditor, setShowEditor] = useState(showCodeByDefault)

  useEffect(() => {
    setShowEditor(showCodeByDefault)
  }, [showCodeByDefault, setShowEditor])

  if (alwaysShowEditor || !isCodePreviewable) {
    return (
      <Box id={`editor-${codeKey}`} mb={'7'} pt={showPadding ? 5 : 0}>
        {children}
      </Box>
    )
  }

  return (
    <Box className={classNames([codeEditorWrapper])}>
      <Box className={classNames([codeEditorToggleContainer])} compactMode>
        <Button
          buttonShape="round"
          onClick={() => setShowEditor(!showEditor)}
          aria-controls={`editor-${codeKey}`}
          skin="tertiary"
          mt={2}
          title={showEditor ? 'Hide code' : 'Show code'}
        >
          <Icon iconType={showEditor ? 'collapse' : 'expand'} />
        </Button>
      </Box>
      <Box
        id={`editor-${codeKey}`}
        aria-expanded={showEditor}
        display={showEditor ? 'block' : 'none'}
        pt={showPadding ? 8 : 0}
        className={classNames([codeEditorContainer])}
      >
        {children}
      </Box>
    </Box>
  )
}

const ReactCodeError = () => {
  return (
    <LiveError
      className={codeError}
      /* Each time this component is rendered (for example, when a user writes bad code in the editor)
      the message in this live region (the error) will be announced by assistive technology (AT) to notify the user of the error.
      The "assertive" setting forces AT to immediately announce the error (rather than wait for the user to pause) since it is urgent. */
      aria-live="assertive"
    />
  )
}

export const CodePreview = ({
  node: config,
  alwaysShowEditor = false,
}: {
  node?: Maybe<SanityCodePreview>
  alwaysShowEditor?: boolean
}) => {
  const [activeTab, setActiveTab] = useState(() => getActiveTabId(config))
  const [compactMode, setCompactMode] = useState(false)
  const [darkMode, setDarkMode] = useState(false)
  const [bgc, setBgc] = useState<swanScope.StyleBackgroundColor>(undefined)

  if (!config) return null

  const {
    _key: codeKey,
    codes,
    reactCode,
    renderMultipleComponents,
    htmlCode,
    editorMode,
    previewWithoutScroll,
  } = config

  const codeContextValues = {
    codeKey,
    compactMode,
    setCompactMode,
    darkMode,
    setDarkMode,
    bgc,
    setBgc,
    previewWithoutScroll,
    editorMode,
    renderMultipleComponents,
    alwaysShowEditor,
  }

  const contextWrapperProps = {
    codeContextValues,
    reactCode,
    htmlCode,
    renderMultipleComponents,
  }

  const hasReactCode = !!reactCode?.code
  const hasHtmlCode = !!htmlCode?.code
  const hasOtherCode = codes?.length === 1
  const hasOtherCodes = codes?.length > 0
  const isCodePreviewable = hasReactCode || hasHtmlCode

  if (editorMode === EDITOR_MODE.PREVIEW_ONLY) {
    if (isCodePreviewable) {
      return (
        <ContextWrapper {...contextWrapperProps}>
          <PreviewWrapper>
            {hasReactCode ? (
              <CodeLivePreviewReact />
            ) : hasHtmlCode ? (
              <CodeLivePreviewHtml />
            ) : null}
          </PreviewWrapper>
        </ContextWrapper>
      )
    } else {
      return null
    }
  }

  if (hasReactCode && !hasHtmlCode && !hasOtherCode) {
    return (
      <ContextWrapper {...contextWrapperProps}>
        <PreviewWrapper>
          <CodeLivePreviewReact />
        </PreviewWrapper>
        <EditorWrapper showPadding>
          <CodeLiveEditorReact />
          <ReactCodeError />
        </EditorWrapper>
      </ContextWrapper>
    )
  }

  if (!hasReactCode && hasHtmlCode && !hasOtherCode) {
    return (
      <ContextWrapper {...contextWrapperProps}>
        <PreviewWrapper>
          <CodeLivePreviewHtml />
        </PreviewWrapper>
        <EditorWrapper showPadding>
          <CodeLiveEditorHtml />
        </EditorWrapper>
      </ContextWrapper>
    )
  }

  if (!hasReactCode && !hasHtmlCode && hasOtherCode) {
    return (
      <ContextWrapper {...contextWrapperProps}>
        <EditorWrapper isCodePreviewable={false}>
          <CodeEditor
            code={codes[0]?.code}
            language={codes[0]?.language}
            disabled
          />
        </EditorWrapper>
      </ContextWrapper>
    )
  }

  return (
    <ContextWrapper {...contextWrapperProps}>
      {activeTab === 'reactCode' && (
        <PreviewWrapper>
          <CodeLivePreviewReact />
        </PreviewWrapper>
      )}
      {activeTab === 'htmlCode' && (
        <PreviewWrapper>
          <CodeLivePreviewHtml />
        </PreviewWrapper>
      )}
      <EditorWrapper isCodePreviewable={isCodePreviewable}>
        <Tabs
          selectedTabId={activeTab}
          onRequestTabChange={requestedTabId => setActiveTab(requestedTabId)}
          showDividingLine={false}
        >
          <TabsHeaders px="6" compactMode>
            {hasReactCode && (
              <TabHeader tabId="reactCode">
                {reactCode?.filename || 'React'}
              </TabHeader>
            )}
            {hasHtmlCode && (
              <TabHeader tabId="htmlCode">
                {htmlCode?.filename || 'Vanilla'}
              </TabHeader>
            )}
            {hasOtherCodes &&
              codes?.map(code =>
                code && code._key ? (
                  <TabHeader key={code._key} tabId={code._key}>
                    {code?.filename || code?.language}
                  </TabHeader>
                ) : null,
              )}
          </TabsHeaders>
          <TabsContents pt={0}>
            {hasReactCode && (
              <TabContent tabId="reactCode">
                <CodeLiveEditorReact />
                <ReactCodeError />
              </TabContent>
            )}
            {hasHtmlCode && (
              <TabContent tabId="htmlCode">
                <CodeLiveEditorHtml />
              </TabContent>
            )}
            {hasOtherCodes &&
              codes?.map(code =>
                code && code._key ? (
                  <TabContent key={code._key} tabId={code._key} pt={0} mt={0}>
                    <CodeEditor
                      code={code.code}
                      language={code.language}
                      disabled
                    />
                  </TabContent>
                ) : null,
              )}
          </TabsContents>
        </Tabs>
      </EditorWrapper>
    </ContextWrapper>
  )
}
