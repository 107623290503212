import {
  Accordion,
  Box,
  Collapsible,
  CollapsibleContent,
  CollapsibleSummary,
  CollapsibleSummaryButton,
  Link,
} from '@vp/swan'
import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { PageTitle, SlugPrefix } from '../../constants/slug-prefix'
import { useAllPages } from '../../hooks/use-all-pages.hook'
import { Logo } from '../layout/top-nav/logo/logo.component'
import {
  active,
  linkContainer,
  linkItem,
  logo,
  menu,
  menuItem,
} from './menu.module.scss'

const defaultCollapsiblesState = {
  [PageTitle.componentPage]: false,
  [PageTitle.foundationPage]: false,
  [PageTitle.contentPage]: false,
  [PageTitle.documentationPage]: false,
  [PageTitle.patternPage]: false,
}

const getPageTitle = (activeLink?: string) => {
  let titleKey = ''
  Object.keys(SlugPrefix).forEach(key => {
    const slug = SlugPrefix[key]
    if (activeLink.includes(slug)) {
      titleKey = key
    }
  })
  return PageTitle[titleKey]
}

export const Menu = ({ activeLink }: { activeLink?: string }) => {
  const [expandedCollapsibles, setExpandedCollapsibles] = useState(
    defaultCollapsiblesState,
  )

  const allPages = useAllPages()

  useEffect(() => {
    if (activeLink === `${SlugPrefix.swan}/`) {
      // Close all the collapsibles when the user is on SWAN landing page
      setExpandedCollapsibles(defaultCollapsiblesState)
    } else {
      const pageTitle = getPageTitle(activeLink)
      if (
        Object.keys(expandedCollapsibles).includes(pageTitle) &&
        !expandedCollapsibles[pageTitle]
      ) {
        handleExpandedCollapsibles(pageTitle, true)
      }
    }
    // if we include expandedCollapsibles in the dependency then it will prevent the closing of the CollapsibleSummary
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeLink])

  const handleExpandedCollapsibles = (
    collapsibleId: string,
    expanded: boolean,
  ) => {
    setExpandedCollapsibles(prevValue => ({
      ...prevValue,
      [collapsibleId]: expanded,
    }))
  }

  return (
    <nav className={menu}>
      <Box className={logo}>
        <Logo isSwanPage={true} />
      </Box>
      <Accordion
        className={menuItem}
        expandedCollapsibles={expandedCollapsibles}
        onRequestExpandedChange={handleExpandedCollapsibles}
      >
        {allPages.map(entity => (
          <Collapsible key={entity.title} collapsibleId={entity.title}>
            <CollapsibleSummary>
              <CollapsibleSummaryButton>
                {entity.title}
              </CollapsibleSummaryButton>
            </CollapsibleSummary>
            <CollapsibleContent className={linkContainer}>
              {entity.nodes.map(u => (
                <Link
                  key={u.id}
                  skin="unstyled"
                  my={'4'}
                  to={u.slug.current}
                  className={classNames(linkItem, {
                    [active]: `${u.slug.current}/` === activeLink,
                  })}
                >
                  {u.title}
                </Link>
              ))}
            </CollapsibleContent>
          </Collapsible>
        ))}
      </Accordion>
    </nav>
  )
}
