import { useCallback, useEffect, useState } from 'react'

type TCopyStatus = 'INITIAL' | 'DONE' | 'ERROR'

export function useCopyToClipboard({ statusResetTime = 750 } = {}) {
  const [copyStatus, setCopyStatus] = useState<TCopyStatus>('INITIAL')
  useEffect(() => {
    // Timer to clear copyStatus after statusResetTime
    const timer = setTimeout(() => {
      if (copyStatus !== 'INITIAL') {
        setCopyStatus('INITIAL')
      }
    }, statusResetTime)
    return () => clearTimeout(timer)
  }, [copyStatus, statusResetTime])

  const copyToClipboard = useCallback((content: string) => {
    navigator.clipboard
      .writeText(content)
      .then(() => setCopyStatus('DONE'))
      .catch(e => {
        console.error('Could not copy text >>', e)
        setCopyStatus('ERROR')
      })
  }, [])

  return { copyStatus, copyToClipboard }
}
